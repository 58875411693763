import styled from 'styled-components';
import { darken } from 'polished';
import { Link } from 'gatsby';

import { COLOR, PATTERN } from '../../constants';

export const HeaderWrapper = styled.div`
  position: relative;
  top: 0px;
  z-index: 5;
  //max-width: 1460px;

  @media screen and (max-width: 479px) {
    position: fixed;
  }
`;

export const HeaderDesktop = styled.div`
  display: flex;
  //flew-flow: row wrap;
  padding: 5px 25px 5px 25px;
  background-color: ${COLOR.HEADER_FOOTER_COLOUR};

  @media screen and (max-width: 479px) {
    display: none;
  }
`;

export const HeaderMobile = styled.div`
  display: none;

  position: absolute;
  z-index: 50;

  @media screen and (max-width: 479px) {
    display: block;
  }
`;

export const SiteTitle = styled.h1`
  line-height: 40px;
  font-weight: 200;
  font-size: 36px;
  padding-left: 4px;
  letter-spacing: -1.5px;
  margin: 0;
  color: ${COLOR.BLACK};
  & > * {
    margin-left: 1rem;
    color: ${COLOR.BLACK};
    cursor: pointer;

    &:focus,
    &:hover {
      text-decoration: none;
      color: ${darken(0.2, COLOR.WHITE)};
    }
  }
`;

export const NavigationLink = styled(Link)`
  border: none;
  margin: 0px;
  padding: 0px 8px;
  position: relative;
  background:url(${props => PATTERN[props.theme.primary.toUpperCase()]});
  //line-height: 32px;
 //margin-left: 1rem;
  color: ${COLOR.BLACK};
  font-weight: 100;
  word-break: break-all;
 // cursor: pointer;
 
  min-width: 16.667%;
  
  height: 36px;

  display: inline-block;

  @media screen and (max-width: 1024px) {
    font-size: 16px;
    height: 26px;
  }
  @media screen and (max-width: 768px) {
    font-size: 15px;
    height: 25px;
  }
  
  &.active,
  &:focus,
  &:hover,
  &:active {
    background:url(${props => PATTERN['FLAT' + props.theme.primary.toUpperCase()]});
    color: ${COLOR.BLACK};
  }

  @media screen and (max-width: 479px) {
    font-weight: 400;
    font-size: 24px;
    display: block;
    color: ${COLOR.WHITE};
    min-height: unset;
    max-height: unset;
    height: unset;
  }
`;

export const Navigation = styled.div`

    display: flex;
    flex-direction: row-reverse;    
    flex-wrap: wrap-reverse;
    align-content: flex-start;

    bottom: 0px;
    right: 0px;
    width: 100%;
    height: 100%;
    position:absolute;

    text-align: right;
    position: relative;
    font-family: 'Source Code Pro', monospace;
    font-size: 22px;

   @media screen and (max-width: 479px) {
     top: 88px;
     display: block;
     visibility: ${props => props.hidden ? "hidden" : "visible"};

    position: fixed;
    z-index: 99;
    font-size: 28px;

    & > * {
        display: block;
        // margin: 4px;
        text-align: right;
        min-width: 0;
        margin-left: auto;
        margin-right: 0;
        padding: 10px;
        padding-right: 12px;
        padding-left: 12px;
        color: ${props => props.root ? "white" : "black"};
      }
      & > *::before {
        left: 0px;
       // margin-left: -12px;
        margin-top: -10px;
        content: "";
        background: ${props => props.root ? "rgba(0,0,0,0.3)" : "rgba(255,255,255,0.9)"};
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: -1;
      }
  }
`;

export const NavigationNoFloat = styled.div`

  font-family: 'Source Code Pro', monospace;
  font-size: 36px;
  
  padding: -10px;

  & > * {
  float: right;
    display: inline-block;
    margin: 4px;
    text-align: right;
    min-width: 0;
    margin-left: auto;
    margin-right: 0;
    padding: 10px;
    padding-right: 12px;
    padding-left: 12px;
    color: white;
  }
  & > *::before {
    margin-left: -12px;
    margin-top: -10px;
    content: "";
    background: rgba(100,100,100,0.2);
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
  }
`;