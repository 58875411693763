import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql, Link } from 'gatsby';

import { Header } from '../Header';
import { Footer } from '../Footer';
import { Container } from '../Container';
import { ResetStyle } from '../Reset';
import { NAV_ITEMS, PATTERN } from '../../constants';
import { NavigationLink, Navigation } from '../Header/styled';
import { HeaderWrapper, HeaderDesktop, HeaderMobile, SiteTitle  } from '../Header/styled';
import { Page, GridLayout } from './styled';
import { Masked } from '../Masked';
import { Hamburger } from '../Hamburger';
import { HamburgerIcon } from '../Hamburger/styled';
import { COLOR } from '../../constants';
import { darken } from 'polished';

import dcdc from '../../assets/dcdc.png'

import { slide as Menu } from 'react-burger-menu'

import Responsive from 'react-responsive';
//const Desktop = props => <Responsive {...props} minWidth={480} />
//const Mobile = props => <Responsive {...props} maxWidth={479} />
class Desktop extends React.Component {
  constructor(props) {
    super(props)
    this.state = {client:false}
  }
  componentDidMount() {
    this.setState({client:true})
  }
  render() {
    return this.state.client ? <Responsive {...this.props} minWidth={480}/> : null
  }
}
class Mobile extends React.Component {
  constructor(props) {
    super(props)
    this.state = {client:false}
  }
  componentDidMount() {
    this.setState({client:true})
  }
  render() {
    return this.state.client ? <Responsive {...this.props} maxWidth={479}/> : null
  }
}

var bmstyles = {
  bmBurgerButton: {
    position: 'fixed',
    width: '36px',
    height: '30px',
    left: '36px',
    top: '36px'
  },
  bmBurgerBars: {
    background: '#373a47'
  },
  bmBurgerBarsHover: {
    background: '#a90000'
  },
  bmCrossButton: {
    height: '24px',
    width: '24px'
  },
  bmCross: {
    background: '#bdc3c7'
  },
  bmMenuWrap: {
    position: 'fixed',
    height: '100%'
  },
  bmMenu: {
    background: '#dcdcdc',
    paddingTop: '1em',
    paddingRight: '1em',
    paddingLeft: '0',
    //fontSize: '1.15em'
  },
  bmMorphShape: {
    fill: '#373a47'
  },
  bmItemList: {
    color: '#b8b7ad',
    padding: '0.8em'
  },
  bmItem: {
    // display: 'inline-block'
  },
  bmOverlay: {
    background: 'rgba(0, 0, 0, 0.3)'
  }
}


const Layout = ({ children, location }) => {
  const navigationItems = NAV_ITEMS.map(({ to, href, label }) => {
    if (href) {
      return (
        <a key={label} href={href}>
          {label}
        </a>
      );
    }

    return (
      <Link key={label} to={to}>
        {label}
      </Link>
    );
  });

  const [mobileMenuVisible, setMobileMenuVisible] = useState( !!location ? "hidden" : "visible")
  const toggleNav = event => {
    if (mobileMenuVisible == "hidden") {
      setMobileMenuVisible("visible")
    } else {
      setMobileMenuVisible("hidden")
    }
  }

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
          allSitePage(filter: {
            context:{
            frontmatter: {
              area: {
                eq: "home"
              }
            }
            }
          }) {
            edges {
              node {
                id
                path
                context {          
                  frontmatter {
                    title
                    theme
                    order
                  }
                }
              }
            }
          }
        }
      `}
      render={data => (
        <Page style={{background:"repeating-linear-gradient(135deg, rgb(250, 250, 250), rgb(245, 245, 245) 100px)"}}>
          <ResetStyle />
          
          <Helmet
            title={data.site.siteMetadata.title}
            meta={[{ name: 'description', content: 'Sample' }, { name: 'keywords', content: 'sample, something' }]}
          >
            <html lang="en" />
          </Helmet>

          <GridLayout>
            <Desktop>
              <div className="homelink">
                <Link to="/">
                  <SiteTitle>Digital Cooperative<br />Development Consortium</SiteTitle>
                </Link>
              </div>
              <div className="navigation">
                <Navigation root={!location}>
                {data.allSitePage.edges.sort((a,b)=>a.node.context.frontmatter.order - b.node.context.frontmatter.order).map(edge => { return (
                    <NavigationLink activeClassName="active" theme={{primary: edge.node.context.frontmatter.theme}} order={edge.node.context.frontmatter.order} key={edge.node.context.frontmatter.title} to={edge.node.path}>{ edge.node.context.frontmatter.title }</NavigationLink>
                  ); })}
                </Navigation>
              </div>
            </Desktop>

            <Mobile>
              
                <div className="homelink">
                  <Link to="/">
                    <Masked strokeColor={!!location ? "#606060": "#ffffff"} style={{ position: "fixed", paddingLeft: "20px", paddingTop: "20px", width: "36%", zIndex: 99 }}></Masked>
                  </Link>
                </div>
                <div className="navigation"><HamburgerIcon strokeColor={!!location ? "#606060": "#ffffff"} style={{zIndex:99}} onClick={toggleNav}></HamburgerIcon>
                
                  <Navigation root={!location} style={{visibility:mobileMenuVisible}}>
                  {data.allSitePage.edges.sort((a,b)=>a.node.context.frontmatter.order - b.node.context.frontmatter.order).map(edge => { return (
                      <NavigationLink activeClassName="active" theme={{primary: edge.node.context.frontmatter.theme}} order={edge.node.context.frontmatter.order} key={"m"+edge.node.context.frontmatter.title} to={edge.node.path}>{ edge.node.context.frontmatter.title }</NavigationLink>
                    ); })}
                  </Navigation>
                </div>
              
            </Mobile> 


            <div className="content">
            {!!children.type && children.type.displayName === 'styled__TopImageWrapper' ? (
              children
            ) : (
              <>
                    <Mobile>
                      <div style={{ paddingBottom: "80px" }}></div>
                      <div className="header" style={{
                        zIndex: 98,
                        height: "90px",
                        position: "fixed",
                        width: "100vw",
                        marginTop: "-1px",
                        border: "none",
                        background: "repeating-linear-gradient(135deg, rgb(250, 250, 250), rgb(245, 245, 245) 100px)"
                      }}></div>
                    </Mobile>
              <Container>{children}</Container>
              </>
            )}
            </div>
            
            
            <div className="footer">
              <Footer></Footer>
            </div>
          </GridLayout>

        </Page>
      )}
    />
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export { Layout };
