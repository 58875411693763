import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import { Hamburger } from '../Hamburger';
import { Masked } from '../Masked';
import { SmallLogo } from '../Layout/styled';
import { HeaderWrapper, HeaderDesktop, HeaderMobile, SiteTitle, Navigation } from './styled';

const Header = ({ title, navigation }) => (
  <HeaderWrapper>
    
      <HeaderDesktop>
        <Link to="/">
          <SiteTitle>Digital Cooperative<br/>Development Consortium</SiteTitle>
        </Link>
      </HeaderDesktop>

      <HeaderMobile>
        <Link to="/">          
            <Masked style={{position: "fixed", paddingLeft:"20px", paddingTop:"20px", width:"36%"}}></Masked>
        </Link>     
        <Hamburger navigation={navigation} menuActive={true}></Hamburger>
      </HeaderMobile> 

  </HeaderWrapper>
);

Header.propTypes = {
  /** Title for the site */
  title: PropTypes.string,
  /** Array of navigation items */
  navigation: PropTypes.arrayOf(PropTypes.node),
};

Header.defaultProps = {
  title: null,
  navigation: null,
};

export { Header };
