import React from 'react'
import createReactClass from 'create-react-class'
import { logo } from "../../assets/logo_huge_png.png"
import { PATTERN } from '../../constants'

export class Masked extends React.Component{

    _scale = 1
    _id = ""
    _parentid = ""
    _memo = {}

    constructor(props) {
        super(props)
        this._id = "pattern" + Math.random().toString(36).replace(/[^a-z]+/g, '')
        this._parentid = "parent_" + this._id
        this._scale = props.scale || 1.0
        this.state = { width: 0, height: 0 }
    }

    getScale() {
        return this._scale ||1.0
    }

    getInitialState() {
        return {
            width: 1920 * 0.003 * 120.5 * this.getScale(), 
            height: 1080 * 0.003 * 42.27 * this.getScale()
        }
    }
        
    componentDidMount() {
        console.log("mounting")
        requestAnimationFrame(() => this.updateWindowDimensions.bind(this)())
        //this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions.bind(this));
    }
    
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions.bind(this));
    }
    
    updateWindowDimensions() {
        if (this.refs.parent == undefined)
            return
        const dim = this.refs.parent.getBoundingClientRect()
        //debugger
        // memo
        const dimmemo = `${Math.ceil(dim.width)},${Math.ceil(dim.height)}`
        if (this.memo == dimmemo)
            return
        this.memo = dimmemo
        console.log(dimmemo)
        //
        let widthfac = dim.width / 120.5
        let heightfac = dim.height / 42.27
        let apply = Math.min(widthfac, heightfac)

        // fixup "" or "auto" width/height
        if (!this.props.style || !this.props.style.width || this.props.style.width == "auto") {
            if (heightfac > 0) {
                apply = heightfac
                this.refs.parent.style.width = `${heightfac * 120.5}px`
               // return this.updateWindowDimensions()
            } else {
                this.refs.parent.style.width = "120.5px"
            }
        }
        if (!this.props.style || !this.props.style.height || this.props.style.height == "auto") {
            if (widthfac > 0) {
                apply = widthfac
                this.refs.parent.style.height = `${widthfac * 42.27}px`
               // return this.updateWindowDimensions()
            } else {
                this.refs.parent.style.height = "42.27px"
            }
        }
        

        this.setState({
            width: dim.width,
            height: dim.height
        })

        this._scale = 1.0 * apply

        requestAnimationFrame(this.updateWindowDimensions.bind(this))
    }

    patternId() {
        return this._id
    }

    parentId() {
        return this._parentid
    }

    render() {
        console.log('render') 
        console.log(this.props)
        let self = this
        return (
            <div id={self.parentId()} style={self.props.style} ref="parent">
                <div style={{position:"absolute", zIndex: 5, height: self.state.height, width: self.state.width}}>        
                    <svg width="100%" height="100%" baseProfile="full" version="1.2">
                        <defs>
                            <pattern id={self.patternId()}
                                    x="0" y="0" width="5" height="5"
                                    patternUnits="userSpaceOnUse" patternTransform={`rotate(45 0 0) scale(${1.0/(self.getScale())})`}>
                                <line x1="0" y1="0" x2="0" y2="10" style={{stroke:self.props.strokeColor || "#ededed", strokeWidth:4}} />
                            </pattern>
                        </defs>
                        <image xlinkHref={logo} />
                        <g style={{fill: "url(#" + self.patternId() + ")"}}  >
                            <g transform={`scale(${self.getScale()})`}>
                                <g>
                                    <path d="M108.19,10.36h13.68V1.22H105.49c-9.79,0-14,3.41-14,11.39V32.16c0,7.94,4.18,11.33,14,11.33h16.38v-9H108.19c-4.63,0-5.33-2.83-5.33-4.52V15C102.86,13.27,103.56,10.36,108.19,10.36Z" transform="translate(-1.37 -1.22)"/>
                                    <path d="M90,12.61a13.49,13.49,0,0,1,2.09-7.92c-2.21-2.37-5.93-3.47-11.46-3.47H48.14c-9.79,0-14,3.41-14,11.39V32.16c0,7.94,4.17,11.33,14,11.33h9.07v-9H50.85c-4.64,0-5.33-2.83-5.33-4.52V15c0-1.73.69-4.64,5.33-4.64l6.37,0h1.49V43.49H80.66c5.53,0,9.25-1.09,11.46-3.46A13.3,13.3,0,0,1,90,32.16ZM83.42,30c0,2.95-1.58,4.57-4.45,4.57H70V10.24h9c2.83,0,4.45,1.64,4.45,4.51Z" transform="translate(-1.37 -1.22)"/>
                                    <path d="M32.68,12.61a13.45,13.45,0,0,1,2.1-7.92c-2.21-2.37-5.93-3.47-11.46-3.47H1.37V43.49h22c5.52,0,9.24-1.09,11.45-3.46a13.4,13.4,0,0,1-2.09-7.87ZM26.07,30c0,2.95-1.58,4.57-4.45,4.57h-9V10.24h9c2.83,0,4.45,1.64,4.45,4.51Z" transform="translate(-1.37 -1.22)"/>
                                </g>
                            </g>
                        </g>
                    </svg>
                </div>
            </div>
        ) 
    }
}
