import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Navigation, NavigationNoFloat } from '../Header/styled';
import { HamburgerWrapper, HamburgerIcon, NavWrapper } from './styled';

class Hamburger extends Component {
  constructor(props) {
    debugger
    super(props);
    this.state = {
      menuActive: false
    };
  }

  toggleMenu = (event) => {
    console.log("toggle menu")
    let active = this.state.menuActive;
    this.setState({ menuActive: !active });
  };

  render() {
    const self = this
    return (
      <HamburgerWrapper>
        <HamburgerIcon onClick={self.toggleMenu.bind(self)}>
        </HamburgerIcon>
        <NavWrapper menuActive={self.state.menuActive}>
          {self.props.navigation && <Navigation>{self.props.navigation}</Navigation>}
        </NavWrapper>         
      </HamburgerWrapper>
    )
  }
}

export { Hamburger };
