import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FOOTER } from '../../constants';

const Wrapper = styled.div`
  grid-column-start: content-col1;
  grid-column-end: content-col4;
  //margin: 0 auto;
  max-width: 1336px;
  padding-left: 6px;

  @media screen and (max-width: 479px) {
    padding: 1.5rem;
  }
  ${({ isDebug }) => isDebug && 'background-color: orange;'}
`;

const Container = ({ children, ...props }) => <Wrapper {...props}>{children}</Wrapper>;

Container.defaultProps = {
  isDebug: false,
};

Container.propTypes = {
  isDebug: PropTypes.bool,
};

export { Container };
