import { Link,image } from "gatsby"
import React from "react"
import twitterIcon from "../../assets/twitter.png"
import tumblrIcon from "../../assets/TumblrBox.png"
import envelopeIcon from "../../assets/STOPLOOKINGATENVELOPES.png"
import tumblrWord from "../../assets/TumblrWord.png"
import coopIcon from "../../assets/cuk_member.png"
import rules from "../../assets/Rules.pdf"
import {CoopIcon, StyledLink, TumblrLink, Envelope, FooterWrapper, Icon, IconTray, ContactLinks, Disclaimer, HideForMobile} from './styled';

const Footer = ({}) => (
  <FooterWrapper>
    <ContactLinks>
      <a href="https://twitter.com/dcdcio?lang=en">
        @dcdcio <Icon src={twitterIcon} alt="twitter icon" /> 
      </a>
      <br/>
      <a href="https://blog.dcdc.io/">
        blog.dcdc.io <Icon src={tumblrIcon} alt="tumblr icon" /> 
      </a>
      <br/>
      <a href="mailto:team@dcdc.io">
        team@dcdc.io <Icon src={envelopeIcon} />
      </a> 
    </ContactLinks>

    <Disclaimer>
      &copy; Copyright 2019 The Digital Cooperative Development Consortium Limited<br/>
      <HideForMobile>
        DCDC is a Multi-Stakeholder Cooperative Consortium registered under the Co-operative and Community Benefit Societies Act 2014<br/>
      </HideForMobile>
      <Link to={"privacy_policy_BROKEN"}>privacy policy</Link> | <Link to={"contact_us"}>contact us</Link> | press kit | <a href={rules}>our cooperative rules</a>
    </Disclaimer>

    <IconTray>
      <a href="https://www.uk.coop/">        
        <CoopIcon id="coopuk" src={coopIcon} alt="co-operatives uk icon" />
      </a>
    </IconTray>  
  </FooterWrapper>
  )
  
  export { Footer }
  
  