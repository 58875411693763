export const TETRADIC_COLOR = {  
  colorprimary0: '#DCDC00',	/* Main Primary color */
  colorprimary1: '#FBFB44',
  colorprimary2: '#F9F91A',
  colorprimary3: '#BEBE00',
  colorprimary4: '#A2A200',

  colorsecondary0: '#D1001D',	/* Main Secondary color (1) */
  colorsecondary1: '#EF4159',
  colorsecondary2: '#ED1936',
  colorsecondary3: '#B50019',
  colorsecondary4: '#9A0015',

  colortertiary0: '#2DBB00',	/* Main Secondary color (2) */
  colortertiary1: '#5FD53A',
  colortertiary2: '#43D316',
  colortertiary3: '#27A100',
  colortertiary4: '#218900',

  colorcomplement0: '#620893',	/* Main Complement color */
  colorcomplement1: '#7E34A7',
  colorcomplement2: '#7419A6',
  colorcomplement3: '#54067F',
  colorcomplement4: '#47046C'
}

export const COLOR = {
  WHITE: '#ffffff',
  BLACK: '#000000',
  REBECCA_PURPLE: '#663399',
  BLUE: '#1a58cc',
  HEADER_FOOTER_COLOUR: '#ffffff'
};

export const PATTERN = {
  PASTELRED:    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAGCAYAAADgzO9IAAAALUlEQVQYV2NkQAP/py/6DxJiRBaHC2bGMcIlkAXhOtAFwRLYBOESjJlxGHYBAN94GnWIhgMsAAAAAElFTkSuQmCC', //#FF97A2
  PASTELORANGE: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAGCAYAAADgzO9IAAAALUlEQVQYV2NkQAP/d2b9BwkxIovDBd2nMcIlkAXhOtAFwRLYBOESjO7TMOwCANE2GkkwxYFdAAAAAElFTkSuQmCC', //#ffb96a
  PASTELYELLOW: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAGCAYAAADgzO9IAAAALElEQVQYV2NkQAP//5f+BwkxIovDBRm7GeESyIJwHeiCYAlsgnAJRsZuDLsAB0oa6zhfWtEAAAAASUVORK5CYII=', //#FFFF75
  PASTELGREEN:  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAGCAYAAADgzO9IAAAALUlEQVQYV2NkQAOT/6/+DxJiRBaHCeYyhjLCJZAF4TrQBcES2AThEiAz0e0CAOHlGn/M1QqSAAAAAElFTkSuQmCC', //#93FFAB
  PASTELBLUE:   'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAGCAYAAADgzO9IAAAALUlEQVQYV2NkQAOzr/7/DxJiRBaHCaZqMzLCJZAF4TrQBcES2AThEiAz0e0CAALCGuNo/mj3AAAAAElFTkSuQmCC', //#9bd5ff

  FLATPASTELRED:   'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAE0lEQVQYV2P8P33RfwYkwEi6AAADVgzhbxU8VQAAAABJRU5ErkJggg==', //#FF97A2
  FLATPASTELORANGE:'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAE0lEQVQYV2P8vzPrPwMSYCRdAAD2OwyJpXb0VgAAAABJRU5ErkJggg==', //#ffb96a
  FLATPASTELYELLOW:'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAE0lEQVQYV2P8/7/0PwMSYCRdAAApYA3NIwheXQAAAABJRU5ErkJggg==', //#FFFF75
  FLATPASTELGREEN: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAE0lEQVQYV2Oc/H/1fwYkwEi6AAAErAz1bqKvYQAAAABJRU5ErkJggg==', //#93FFAB
  FLATPASTELBLUE:  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAAECAYAAACp8Z5+AAAAE0lEQVQYV2OcffX/fwYkwEi6AAAjIA29RhNa7QAAAABJRU5ErkJggg==', //#9bd5ff

  WHITE: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAGCAYAAADgzO9IAAAALUlEQVQYV2NkQAP/////DxJiRBaHCzIyMsIlkAXhOtAFwRLYBOESyEaCBEGKAWJkG/+xHcbRAAAAAElFTkSuQmCC',

  GREEN:     'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAGCAYAAADgzO9IAAAANklEQVQYV2NkuMPwn0GFgZEBGdxh+I8qAJIEKWRgwFQJ1qjCwIjQAVUJMxYigSYIMQqLIEgCAH+4Db0HTwt0AAAAAElFTkSuQmCC',
  RED:       'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAGCAYAAADgzO9IAAAAM0lEQVQYV2O8w8DwX4WBgZEBCYDEUARAciBBEI2hEiQIMgEuAVMJMxYsgS4INgqbIEgCAIZwDb27EXC8AAAAAElFTkSuQmCC',
  PURPLE:    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAGCAYAAADgzO9IAAAAM0lEQVQYV2O8w3DnvwqDCiMDEgCJoQiA5ECCIBpDJUgQZAJcAqYSZixYAl0QbBQ2QZAEALK6FHVQ3q16AAAAAElFTkSuQmCC',
  ORANGE:    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAGCAYAAADgzO9IAAAAM0lEQVQYV2O8c4fhv4oKAyMDEgCJoQiA5ECCIBpDJUgQZAJcAqYSZixYAl0QbBQ2QZAEALlyFHXHkSwcAAAAAElFTkSuQmCC',
  TURQUOISE: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAGCAYAAADgzO9IAAAAN0lEQVQYV2NkuHP2P4OKMSMDMrhz9j+qAEgSpJCBgQFDJVijijEjQgKqEmYsRAJNEGIUFkGQBACcPxRX3WSrQQAAAABJRU5ErkJggg==',
 
  FLATGREEN:     'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAGCAYAAADgzO9IAAAAE0lEQVQYV2NkuMPwnwELYBxICQCutwspDY0PowAAAABJRU5ErkJggg==',
  FLATRED:       'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAGCAYAAADgzO9IAAAAE0lEQVQYV2O8w8DwnwELYBxICQCz3wspqkT5LwAAAABJRU5ErkJggg==',
  FLATPURPLE:    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAGCAYAAADgzO9IAAAAE0lEQVQYV2O8w3DnPwMWwDiQEgBnsRBRzW1eMAAAAABJRU5ErkJggg==',
  FLATORANGE:    'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAGCAYAAADgzO9IAAAAE0lEQVQYV2O8c4fhPwMWwDiQEgBs2RBRcrw7eQAAAABJRU5ErkJggg==',
  FLATTURQUOISE: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAGCAYAAADgzO9IAAAAE0lEQVQYV2NkuHPnPwMWwDiQEgBiiRBRk7X5fAAAAABJRU5ErkJggg==',
};

export const NAV_ITEMS = [
  { to: '/about_us', label: 'About' },
  { to: '/join_us', label: 'Join Us' },
  { to: '/hire_us', label: 'Hire Us' },
  { to: '/developers', label: 'Developers' },
];

export const FOOTER = {
  DESKTOP_HEIGHT: '12rem',
  PHONE_HEIGHT: '13rem',
  TOP_MARGIN: '6px'
}
