import styled from 'styled-components';
import { COLOR } from '../../constants';
import { FOOTER } from '../../constants';
import { darken } from 'polished';

export const Icon = styled.img`
  height: 28px;
  width: 28px;
  margin: 8px 0 -8px 0;
`;

export const Envelope = styled.img`
  height: 13px;
  padding-left: 5px;
`;

export const TumblrLink = styled.img`
  height: 13px;
  padding-left: 5px;
`;

export const StyledLink = styled.a`
  color: ${COLOR.WHITE};
  &:focus,
  &:hover {
      color: ${darken(0.2, COLOR.WHITE)};
  }
`
export const HideForMobile = styled.div`
@media screen and (max-width: 479px) {
  display :none;
}`

export const FooterWrapper = styled.div`
  border-top: 0.05rem solid ${darken(0.4, COLOR.HEADER_FOOTER_COLOUR)};
  text-align: center;
  display: grid;
  max-width: 1336px;
  //height: ${FOOTER.DESKTOP_HEIGHT};
  grid-template-columns: 1fr 2fr 1fr;
  padding-top: 5px;
  padding-bottom: 10px;
  background-color: ${COLOR.HEADER_FOOTER_COLOUR};
  bottom: 0px;
  position: absolute;

  @media screen and (max-width: 479px) {
      height: ${FOOTER.PHONE_HEIGHT};
      grid-template-columns: 2fr 3fr;
      grid-template-rows: 1fr 2fr;
  }

  /*.left {
      text-align: right;
      padding-right: 20px;
  }*/
`;

export const CoopIcon = styled.img`
  height: 65px;
`;

export const ContactLinks = styled.div`
  margin-top: ${FOOTER.TOP_MARGIN};
  grid-column-start: 1;
  grid-column-end: 2;
  
  @media screen and (max-width: 479px) {
    grid-row-start: 1;
    grid-row-end: 2;
  }
`;

export const Disclaimer = styled.div`
  margin-top: ${FOOTER.TOP_MARGIN};
  grid-column-start: 2;
  grid-column-end: 3;

  @media screen and (max-width: 479px) {
    grid-row-start: 1;
    grid-row-end: 3;
  }
`;

export const IconTray = styled.div`
  margin-top: ${FOOTER.TOP_MARGIN};
  grid-column-start: 3;
  grid-column-end: 4;

  @media screen and (max-width: 479px) {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 3;
  }
`;


