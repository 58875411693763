import styled from 'styled-components';
import { darken } from "polished"
import { FOOTER, COLOR } from '../../constants';
import { Masked } from '../Masked';

import React from "react"

export const Page = styled.div`
    padding: 0px;
    width: 100%;
    position: relative;
    min-height: 100vh;
`;

export const TopImageWrapper = styled.div`
  grid-area: inherit;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  max-width: 100vw;
  // margin-top: -25px;
  position: relative;
  //padding-bottom: ${FOOTER.DESKTOP_HEIGHT};

  @media screen and (max-width: 479px) {
    padding-bottom: ${FOOTER.PHONE_HEIGHT};
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }
`;

export const TopImage = styled.img`
  display: block;
  width: 100%;
`;

export const BigLogo = styled.div`
  overflow: hidden;
  display: block;
  @media screen and (max-width: 479px) {
    display: none;
  }
`;

export const SmallLogo = styled.div`
  display: none;

  @media screen and (max-width: 479px) {
    display: block;
    position: fixed;
    width: 50%;
  }
`;

const StyledTagline = styled.div`
  // background-color: black;
  color: white;
  position: absolute;
  left: 16px;
  font-weight: 300;
  font-size: 36px;
  overflow: hidden;

  max-width: 70%;

  & > * {
    background-color: black;
    padding-left: 8px;
    padding-right: 8px;
    display: inline-block;
  }

  @media screen and (max-width: 479px) {
    display: none;
  }
`

export class Tagline extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    let children = this.props.children
    if (typeof children == "string") {
      children = children.split(/\s+/).map((s, i) => <div key={i}>{s}</div>)
    }
    const style = Object.assign({
      position: "absolute",
      color: "white"
    }, this.props.style || {})
    return (
      <StyledTagline style={style}>
        {children}
      </StyledTagline>
    )
  }
}

export const GridLayout = styled.div`
 // width: 100%;
  max-width: 1336px;
  margin-left: auto;
  margin-right: auto;
  background: rgba(255,255,255,0.5);

  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto auto 200px;
  grid-template-areas: "homelink navigation navigation navigation" "content content content content" "footer footer footer footer";
  min-height: 100%;

.homelink { grid-area: homelink; }

.navigation { grid-area: navigation; }

.content { 
  border-top: 1px solid ${darken(0.4, COLOR.HEADER_FOOTER_COLOUR)};
  grid-area: content;
}

.footer { grid-area: footer; }


@media all and (-ms-high-contrast:none) {
  .layout-grid-container {
    display: -ms-grid;
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    -ms-grid-rows: 0.6fr 1.9fr 0.5fr;
  }

  .layout-homelink {
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    -ms-grid-col: 1;
    -ms-grid-col-span: 1;
  }

  .layout-navigation {
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    -ms-grid-col: 2;
    -ms-grid-col-span: 3;
  }

  .layout-content {
    -ms-grid-row: 2;
    -ms-grid-row-span: 1;
    -ms-grid-col: 1;
    -ms-grid-col-span: 4;
  }

  .layout-footer {
    -ms-grid-row: 3;
    -ms-grid-row-span: 1;
    -ms-grid-col: 1;
    -ms-grid-col-span: 4;
  }

}`