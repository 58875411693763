import styled from 'styled-components';
import { COLOR, PATTERN } from '../../constants';
import React from "react"


export const HamburgerWrapper = styled.div`

`;

export const NavWrapper = styled.div`
    display: ${props => props.menuActive ? 'block' : 'none'};
    position: fixed;
    top: 100px;
    right: 20px;
    left: 100px; // hack to force line-breaks - TODO: should refactor from links from <a> to a button type
`;

export class HamburgerIcon extends React.Component {
    constructor(props) {
        super(props)
    }

    defaultStyle = Object.assign({
        position: "fixed",
        top: "20px",
        right: "20px",
        width: "12vw",
        height: "12vw"
    }, this.props.style)

    render() {
        const self = this
        return <svg onClick={self.props.onClick} style={self.defaultStyle}>
            <pattern id="diagonalHatch" width="5" height="5" patternTransform="rotate(45 0 0)" patternUnits="userSpaceOnUse">
                <line x1="0" y1="0" x2="0" y2="10" style={{stroke:self.props.strokeColor || "#ededed", strokeWidth:4}} />
            </pattern>
            <rect x="0" y="0" width="100%" height="20%" fill="url(#diagonalHatch)"/>
            <rect x="0" y="40%" width="100%" height="20%" fill="url(#diagonalHatch)"/>
            <rect x="0" y="80%" width="100%" height="20%" fill="url(#diagonalHatch)"/>
        </svg>
    }
}
